// @ts-nocheck
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { normalizeMask } from './model';
import { Input } from './input';
import ReactInputMask from 'react-input-mask';
import { useLatest } from 'hooks';
import { CountryCodeModel } from 'domains/cartography';

const FEPhoneField = memo(
  forwardRef<HTMLInputElement, FEPhoneFieldProps>(
    (
      {
        value,
        countries,
        currentCountry,
        disabled = false,
        hasError = false,
        tabIndex,
        onChange,
        onChangeCountry,
        onValid,
      },
      ref,
    ) => {
      const [valid, setValid] = useState<boolean>(false);

      const latestValue = useLatest(value);
      const latestValid = useLatest(valid);
      const latestCountry = useLatest(currentCountry);
      const latestCurrentCountry = useLatest(currentCountry);

      const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
          const country = latestCountry.current;
          const value = e.target.value;
          const prevValid = latestValid.current;
          const valid =
            !!country &&
            value.replace(/[-+()\s]/g, '').length === country.phoneLength;
          if (valid !== prevValid) onValid(valid);
        },
        [onChange, onValid, latestCountry, latestValid],
      );

      useEffect(() => {
        const value = latestValue.current;
        const country = latestCurrentCountry.current;
        if (value && country) {
          const valid =
            value.replace(/[-+()\s]/g, '').length === country.phoneLength;
          onValid(valid);
          setValid(valid);
        } else {
          onValid(false);
          setValid(false);
        }
      }, [latestValue, latestCurrentCountry, onValid]);

      if (!currentCountry) return null;

      return (
        <ReactInputMask
          tabIndex={tabIndex}
          mask={normalizeMask(currentCountry.phoneMask)}
          value={value ?? ''}
          onChange={handleChange}
          alwaysShowMask={true}
          disabled={disabled}
          maskPlaceholder={''}
        >
          <Input
            ref={ref}
            onChangeCountry={(country) => {
              onChangeCountry && onChangeCountry(country);
              onChange('');
            }}
            {...{
              value,
              disabled,
              onChange: handleChange,
              currentCountry,
              countries,
              hasError,
            }}
          />
        </ReactInputMask>
      );
    },
  ),
);

export default FEPhoneField;

export type FEPhoneFieldProps = {
  value: string | null;
  countries: CountryCodeModel[];
  currentCountry: CountryCodeModel | null;
  disabled?: boolean;
  hasError?: boolean;
  tabIndex?: number;
  onChange: (value: string) => void;
  onValid: (valid: boolean) => void;
  onChangeCountry?: (v: CountryCodeModel) => void;
};
